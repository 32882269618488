import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './footer.scss'
import Strings from '../const/strings.tsx'
import Modal from 'react-modal'
import CooliePolicy from '../component/cookie/cookiePolicy.tsx'

const Footer = () => {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  return (
    <div className='footer-body'>
      <Link to='/'>
        <img className='logo' src='https://sekigahara-idolwars.net/static/media/2024/logo/headerlogo.svg' alt='logo'/>
      </Link>
      <div className='footer-content'>
        <button className='modal-btn' onClick={() => setEditModalIsOpen(true)}>プライバシーポリシー</button>
        <Modal isOpen={editModalIsOpen} onRequestClose={() => (setEditModalIsOpen(false))} overlayClassName='overlay' className='modal-md'>
          <CooliePolicy />
        </Modal>
      </div>
      <div className='copy-rights'>{Strings.copyRights}</div>
    </div>
  )
}

export default Footer

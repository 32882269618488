import React, { ReactNode } from 'react'
import './frame.scss'

type FrameProps = {
  children: ReactNode
}

export const Frame = (props: FrameProps) => {
  return (
    <div className='view-container'>
      {props.children}
    </div>
  )
}

import React from 'react';
import { Link } from 'react-router-dom';
import './menu_sp.scss';

interface MenuProp {
  displayMode: string
}

const MenuSp = (props: MenuProp) => {
  const {displayMode} = props;

  return (
    <div className='menu-sp-view' style={{display: `${displayMode}`}}>
      <ul className='nav-ul'>
        <li className='nav-li'><Link to='/about' className='nav-link'>ABOUT<span>関ケ原唄姫合戦</span></Link></li>
        <li className='nav-li'><Link to='/ticket' className='nav-link'>TICKET<span>チケット</span></Link></li>
        <li className='nav-li'><Link to='/lineup' className='nav-link'>LINE UP<span>出演者情報</span></Link></li>
        <li className='nav-li'>
          <div className='nav-link'>TIMETABLE<span>タイムテーブル</span></div>
          <ul className='dropdown-ul'>
            <li className='dropdown-li'><Link to='/live' className='nav-link'>LIVE<span>ライブ</span></Link></li>
            <li className='dropdown-li'><Link to='/greeting' className='nav-link'>GREETING<span>物販・特典会</span></Link></li>
          </ul>
        </li>
        {/* <li className='nav-li'>
          <div className='nav-link'>SHOP<span>ショップ</span></div>
          <ul className='dropdown-ul'>
            <li className='dropdown-li'><Link to='/goods' className='nav-link'>GOODS<span>グッズ</span></Link></li>
            <li className='dropdown-li'><Link to='/foods' className='nav-link'>FOODS<span>飲食情報</span></Link></li>
          </ul>
        </li> */}
        <li className='nav-li'><Link to='/notice' className='nav-link'>NOTICE<span>注意事項</span></Link></li>
        <li className='nav-li'>
          <div className='nav-link'>MAP<span>マップ</span></div>
          <ul className='dropdown-ul'>
            <li className='dropdown-li'><Link to='/map' className='nav-link'>MAP<span>マップ</span></Link></li>
            <li className='dropdown-li'><Link to='/access' className='nav-link'>ACCESS<span>アクセス</span></Link></li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default MenuSp;

import React, { PropsWithChildren } from 'react'
import './squareView.scss'
import { Link } from 'react-router-dom'

type SquareViewProps = {
  page: string
  imgUrl: string
  alt: string
}

export const SquareView = (props: PropsWithChildren<SquareViewProps>) => {
  const {page, imgUrl, alt, children} = props

  return (
    <Link to={page}>
      <div className='square-view'>
        <img className='background-img' src={imgUrl} alt={alt} />
        <div className='front-view'></div>
        <div className='front-text'>{children}</div>
      </div>
    </Link>
  )
}

import React, { createContext, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import HomeView from './home/home.tsx';
import AboutView from './about/about.tsx';
import NewsView from './news/news.tsx';
import NewsDetailView from './news/newsDetail.tsx';
import TicketView from './ticket/ticket.tsx';
import LineupView from './lineup/lineup.tsx';
import TimetableView from './timetable/timetable.tsx';
import GreetingTableView from './timetable/greetimgTable.tsx';
import MapView from './map/map.tsx';
import AccessView from './access/access.tsx';
import NotFoundView from './notfound/notFound.tsx';
import GoodsView from './goods/goods.tsx';
import FoodsView from './foods/foods.tsx';
import YosenView from './yosen/yosen.tsx';
import AttentionView from './attention/attention.tsx';

export const AppContext = createContext({});

const Main = () => {

  const ScrollToTop = () => {
    const { pathname } = useLocation()
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname])
  
    return null
  }

  return (
    <AnimatePresence>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<HomeView />} />
        <Route path='/about' element={<AboutView />} />
        <Route path='/news' element={<NewsView />} />
        <Route path='/news/:newsId' element={<NewsDetailView />} />
        <Route path='/ticket' element={<TicketView />} />
        <Route path='/lineup' element={<LineupView />} />
        <Route path='/live' element={<TimetableView />} />
        <Route path='/greeting' element={<GreetingTableView />} />
        <Route path='/map' element={<MapView />} />
        <Route path='/notice' element={<AttentionView />} />
        <Route path='/access' element={<AccessView />} />
        <Route path='/goods' element={<GoodsView />} />
        <Route path='/foods' element={<FoodsView />} />
        <Route path='/yosen' element={<YosenView />} />
        <Route path='*' element={<NotFoundView />} />
      </Routes>
    </AnimatePresence>
  );
}

const App = () => {
  return (
    <Main />
  );
}
export default App;

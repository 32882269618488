import React from 'react';
import { motion } from 'framer-motion';
import Header from '../../header/header.tsx';
import Footer from '../../footer/footer.tsx';
import CookieConsentView from '../cookie/cookieConsent.tsx';
import './baseView.scss';

interface Props {
  header?: React.ReactNode;
  main?: React.ReactNode;
  footer?: React.ReactNode;
  semiModal?: React.ReactNode;
}

const BaseView:React.FC<Props> = ({
  main,
  semiModal
}) => {

  const pageTransition = {
    duration: 1,
    opacity: { ease: 'easeOut', duration: 1 }
  };
  
  return (
    <div className='base-view' id='base-view-id'>
      <div className='header-space'></div>
      <header>
        <Header />
      </header>
      <main>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={pageTransition}
        >
        {main}
        </motion.div>
      </main>
      <footer>
        <Footer />
      </footer>
      {semiModal}
      <CookieConsentView></CookieConsentView>
      <div className='door-left'></div>
      <div className='door-right'></div>
    </div>
  );
}

export default BaseView;
